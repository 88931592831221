@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@700&family=Poppins:wght@400;500;600&display=swap');
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css");


*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}


/* body { position: absolute; } */

.main{
  margin: 0;
  padding: 0;
  /* background: linear-gradient(120deg,#eb3349, #f45c43); */
  height: 100vh;
  /* overflow: hidden; */
}


.homeHeading{
  color: white;
  font-size: larger;
  text-align: center;
}

.mainHeader{
  width: 100%;
  background: linear-gradient(135deg, #CA9980 0%, #984261 100%);
  height: 300px;
}


.navbar {
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding-left: 20px;
  gap: 3%;
  padding-top: 3%;


}

.navbar a {
  float: left;
  font-size: 18px;
  color:lightgray;
  text-align: center;
  // padding: 14px 16px;
}

.navbarContainer1{
  /* border: 2px solid white; */

  height: 80px;
  width: 20%;
}


.navbarContainer2{
  /* border: 2px solid white; */

  height: 80px;
  width: 30%;
}

.title{
  font-size: 24px;
  color:#F9FAF8;
  /* padding-left: 50px; */

}


ul {
  list-style-type: none;
}

li {
  float: right;
  font-size: 18px;
  color:whitesmoke;
}

li a {
  display: block;
  padding: 8px;
  }

.menuItem:hover {
    text-decoration: underline;
    cursor: pointer;
   }

.donationListHeaderboxContainer {

    /* height: 100px; */
    /* padding-top: 1%; */
    width: 100%;
    /* background-color: black; */
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

}

.donationListHeaderboxContainer h1{
    color: white;
    font-size: 44px;
    text-align: center;
    font-weight: bolder;
}

.donationListHeaderboxContainer h2{
  color: white;
  font-size: 20px;
  text-align: center;
  margin-top: 1%;
  font-weight: lighter;
}


.mainInformationContainer{
    display: flex;
    align-items: center;
    justify-content: space-around;
    
}

.informationContainer{
    margin-top: 2%;
    margin-bottom: 2%;
    background-color: #F8F8F8;
    width: 50%;
    height: 600px;
    /* border: 2px solid black; */

    box-shadow: 0 2px 6px ;
    border-radius: 15px;

    /* display: flex;
    align-items: flex-start;
    justify-content: center; */
}

.mainContainer{
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: row;
    height: 50%;
    width: 100%;
}


.profileHeading{
    //  padding-right: 6%;
    color: black;
    text-align: center;
    /* color: white; */
    font-size: 32px;
    font-weight: bold;
    margin-top: 2%;
    // margin-bottom: 2%;

  }


  .donationNumber{
    color: black;
    text-align: center;
    /* color: white; */
    font-size: 68px;
    font-weight: bold;
    margin-top: 2%;
    // margin-bottom: 2%;
  }



  // @import "compass/css3";

  // More practical CSS...
  // using mobile first method (IE8,7 requires respond.js polyfill https://github.com/scottjehl/Respond)
  
  

  .tableContainer{
    height: 500px;
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    margin-top: 3%;
  }
  
  // presentational styling
  




.infoContainer{
    /* border: 2px solid black; */
    width: 100%;
    height: 50%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    // flex-direction: row;
    gap: 5%;
    margin-top: 1%;


}

.editContainer{
  width: 50%;
  height: 100%;
  background-color: #F8F8F8;
  // margin-bottom: 30%;
  color: black;
  text-align: center;
  /* color: white; */
  font-size: 32px;
  font-weight: lighter;
  padding-top: 2%;
  padding-right: 2%;
  padding-left: 2%;

}



.buttonContainer1{
  // border: 2px solid black; 
  width: 100%;
  height: 12%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: row;
  // gap: 5%;
  margin-top: 2.5%;

}


.editButton1{
  background-color: #3882F6;
  border-radius: 10px;
  border: none;
  font-size: 16px;
  border-color: #1F2937;
  padding: 20px;
  width: 25%;
  color: white;
  font-weight: bold;

}


.editButton1:active{
  /* box-shadow: 0 5px #666; */
  transform: translateY(2px);
}