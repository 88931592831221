@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@700&family=Poppins:wght@400;500;600&display=swap');
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css");


*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}


/* body { position: absolute; } */

.main{
  margin: 0;
  padding: 0;
  /* background: linear-gradient(120deg,#eb3349, #f45c43); */
  height: 100vh;
  /* overflow: hidden; */
}


.homeHeading{
  color: white;
  font-size: larger;
  text-align: center;
}

.mainHeader{
  width: 100%;
  background: linear-gradient(135deg, #CA9980 0%, #984261 100%);
  height: 330px;
}


.navbar {
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding-left: 20px;
  gap: 3%;
  padding-top: 3%;


}
.navbar {
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding-left: 20px;
  gap: 3%;
  padding-top: 3%;


}

.navbar a {
  float: left;
  font-size: 18px;
  color:lightgray;
  text-align: center;
  /* padding: 14px 16px; */
}

.navbarContainer1{
  /* border: 2px solid white; */

  height: 80px;
  width: 20%;
}


.navbarContainer2{
  /* border: 2px solid white; */

  height: 80px;
  width: 30%;
}

.title{
  font-size: 24px;
  color:#F9FAF8;
  /* padding-left: 50px; */

}


ul {
  list-style-type: none;
}

li {
  float: right;
  font-size: 18px;
  color:whitesmoke;
}

li a {
  display: block;
  padding: 8px;
  }

.menuItem:hover {
    text-decoration: underline;
    cursor: pointer;
   }



.profilePageHeaderboxContainer {

    /* height: 100px; */
    /* padding-top: 1%; */
    width: 100%;
    /* background-color: black; */
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

}

.profilePageHeaderboxContainer h1{
    color: white;
    font-size: 44px;
    text-align: center;
    font-weight: bolder;
}

.profilePageHeaderboxContainer h2{
  color: white;
  font-size: 24px;
  text-align: center;
  margin-top: 1%;
  font-weight: lighter;
}


.profilePageHeaderboxContainer h3{
  color: white;
  font-size: 16px;
  text-align: center;
  /* margin-top: 1%; */
  font-weight: lighter;
}


.mainInformationContainer{
    display: flex;
    align-items: center;
    justify-content: space-around;
    
}

.informationContainer{
    margin-top: 2%;
    margin-bottom: 2%;
    background-color: #F8F8F8;
    width: 50%;
    height: 600px;
    /* border: 2px solid black; */

    box-shadow: 0 2px 6px ;
    border-radius: 15px;

    /* display: flex;
    align-items: flex-start;
    justify-content: center; */
}

.mainContainer{
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: row;
    height: 50%;
    width: 100%;
}


.profileHeading{
    /* padding-right: 6%; */
    color: black;
    text-align: center;
    /* color: white; */
    font-size: 24px;
    font-weight: bold;
    margin-top: 2%;
    margin-bottom: 2%;

  }

.smallInformationHeading{
    color: black;
    /* text-align: center; */
    /* color: white; */
    font-size: 20px;
    font-weight: 400;
    /* margin-top: 2%;
    margin-bottom: 2%; */
  }

.smallInformationContainer{
    padding-left: 2%;
    /* border: 2px solid black; */
    height: 100%;
    width: 50%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;

}

.smallInformationContainer1{
    padding-left: 2%;
    /* border: 2px solid black; */
    height: 100%;
    width: 50%;
    /* display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column; */

}


.answerContainer{
    width: 75%;
    height: 25%;
    /* border: 2px solid black; */
    background-color: #f3f3f3;

    box-shadow: 0 1px 2px ;

    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;

}


input{
    color: black;
    /* text-align: center; */
    /* color: white; */
    font-size: 18px;
    width: 100%;
    /* font-weight: lighter; */
    padding-left: 2%;
    background-color: #f3f3f3;
    /* margin-top: 2%;
    margin-bottom: 2%; */
    border: 2px solid #f3f3f3;

}


.answerLabel{
  color: black;
  /* text-align: center; */
  /* color: white; */
  font-size: 18px;
  width: 100%;
  /* font-weight: lighter; */
  padding-left: 2%;
  background-color: #f3f3f3;
  /* margin-top: 2%;
  margin-bottom: 2%; */
  border: 2px solid #f3f3f3;
}


.buttonContainer{
    /* border: 2px solid black; */
    width: 100%;
    height: 20%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    gap: 5%;
    margin-top: 0.5%;


}



  .editButton{
    background-color: #3882F6;
    border-radius: 10px;
    border: none;
    font-size: 16px;
    border-color: #1F2937;
    padding: 10px;
    width: 25%;
    color: white;
    font-weight: bold;

  }

  
  .editButton:active{
    /* box-shadow: 0 5px #666; */
    transform: translateY(2px);
  }

  .listButton{
    background-color: whitesmoke;
    border-radius: 10px;
    border: none;
    font-size: 16px;
    border-color: #1F2937;
    padding: 10px;
    width: 25%;
    color: black;
    font-weight: bold;
    /* box-shadow: 0 1px 2px ; */

  }
  
  .listButton:active{
    /* box-shadow: 0 5px #666; */
    transform: translateY(2px);
  }


  .buttonContainer2{
    /* // border: 2px solid black;  */
    width: 100%;
    height: 15%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: row;
    /* // gap: 5%; */
    /* margin-top: 2.5%; */
  
  
  }
  
  
  .editButton2{
    background-color: #3882F6;
    border-radius: 10px;
    border: none;
    font-size: 16px;
    border-color: #1F2937;
    padding: 20px;
    width: 25%;
    color: white;
    font-weight: bold;
  
  }
  
  
  .editButton2:active{
    /* box-shadow: 0 5px #666; */
    transform: translateY(2px);
  }