@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@700&family=Poppins:wght@400;500;600&display=swap');
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css");


*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}


/* body { position: absolute; } */

.main{
  margin: 0;
  padding: 0;
  /* background: linear-gradient(120deg,#eb3349, #f45c43); */
  height: 100vh;
  /* overflow: hidden; */
}


.homeHeading{
  color: white;
  font-size: larger;
  text-align: center;
}

.mainHeader{
  width: 100%;
  background: linear-gradient(135deg, #CA9980 0%, #984261 100%);
  height: 300px;
}


.navbar {
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding-left: 20px;
  gap: 3%;
  margin: 0px;
  padding-top: 3%;
  
 }
  

.navbar a {
  float: left;
  font-size: 18px;
  color:lightgray;
  text-align: center;
  /* padding: 14px 16px; */
}

.navbarContainer1{
  /* border: 2px solid white; */

  height: 80px;
  width: 20%;
}


.navbarContainer2{
  /* border: 2px solid white; */

  height: 80px;
  width: 30%;
}

.title{
  font-size: 24px;
  color:#F9FAF8;
  /* padding-left: 50px; */

}


ul {
  list-style-type: none;
}

li {
  float: right;
  font-size: 18px;
  color:whitesmoke;
}

li a {
  display: block;
  padding: 8px;
  }

.menuItem:hover {
    text-decoration: underline;
    cursor: pointer;
   }


.individualNGOPageContainer {

/* height: 100px; */
/* padding-top: 1%; */
width: 100%;
/* background-color: black; */
display: flex;
align-items: center;
justify-content: center;
flex-direction: column;

}

.individualNGOPageContainer h1{
    color: white;
    font-size: 44px;
    text-align: center;
    font-weight: bolder;
}

.individualNGOPageContainer h2{
  color: white;
  font-size: 20px;
  text-align: center;
  margin-top: 1%;
  font-weight: lighter;
}

.profileHeading{
  /* padding-right: 6%; */
  color: black;
  text-align: center;
  /* color: white; */
  font-size: 32px;
  font-weight: bold;
  margin-top: 2%;
  /* margin-bottom: 2%; */

}

.infoContainer{
  /* border: 2px solid black; */
  width: 100%;
  height: 73%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  /* flex-direction: row; */
  gap: 5%;
  margin-top: 1%;

}

.editContainer{
  width: 100%;
  height: 100%;
  background-color: #F8F8F8;
  /* margin-bottom: 30%; */
  color: black;
  text-align: center;
  /* color: white; */
  font-size: 18px;
  font-weight: lighter;
  padding-right: 2%;
  padding-left: 2%;

}

.phoneNumberContainer{
  width: 100%;
  height: 10%;
  background-color: #F8F8F8;
  /* margin-bottom: 30%; */
  color: black;
  text-align: center;
  /* color: white; */
  font-size: 18px;
  font-weight: lighter;
  padding-right: 2%;
  padding-left: 2%;
}
.addressContainer{
  width: 100%;
  height: 15%;
  background-color: #F8F8F8;
  /* margin-bottom: 30%; */
  color: black;
  text-align: center;
  /* color: white; */
  font-size: 18px;
  font-weight: lighter;
  padding-right: 2%;
  padding-left: 2%;
}

.addressContainer2{
  width: 100%;
  height: 20%;
  background-color: #F8F8F8;
  /* margin-bottom: 30%; */
  color: black;
  text-align: center;
  /* color: white; */
  font-size: 18px;
  font-weight: lighter;
  padding-right: 2%;
  padding-left: 2%;
}

.emailContainer{
  width: 100%;
  height: 10%;
  background-color: #F8F8F8;
  /* margin-bottom: 30%; */
  color: black;
  text-align: center;
  /* color: white; */
  font-size: 18px;
  font-weight: lighter;
  padding-right: 2%;
  padding-left: 2%;
}

.verticalContainer{
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  gap: 5%;
  margin-top: 1%;
}