@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@700&family=Poppins:wght@400;500;600&display=swap');
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css");


*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

.main{
  margin: 0;
  padding: 0;
  /* background: linear-gradient(120deg,#eb3349, #f45c43); */
  height: 100vh;
  /* overflow: hidden; */
}


.homeHeading{
  color: white;
  font-size: larger;
  text-align: center;
}

.mainHeader{
  width: 100%;
  background: linear-gradient(135deg, #3C8CE7 0%, #00C3D5 100%);
  height: 300px;
}


.navbar {
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding-left: 20px;
  gap: 3%;
  padding-top: 3%;


}

.navbar a {
  float: left;
  font-size: 18px;
  color:lightgray;
  text-align: center;
  /* padding: 14px 16px; */
}

.navbarContainer1{
  /* border: 2px solid white; */

  height: 80px;
  width: 20%;
}


.navbarContainer2{
  /* border: 2px solid white; */

  height: 80px;
  width: 30%;
}

.title{
  font-size: 24px;
  color:#F9FAF8;
  /* padding-left: 50px; */

}


ul {
  list-style-type: none;
}

li {
  float: right;
  font-size: 18px;
  color:whitesmoke;
}

li a {
  display: block;
  padding: 8px;
  }

.menuItem:hover {
    text-decoration: underline;
    cursor: pointer;
   }




.donateFormHeaderboxContainer {

    /* height: 100px; */
    /* padding-top: 1%; */
    width: 100%;
    /* background-color: black; */
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

}

.donateFormHeaderboxContainer h1{
    color: white;
    font-size: 44px;
    text-align: center;
    font-weight: bolder;
}

.donateFormHeaderboxContainer h2{
  color: white;
  font-size: 20px;
  text-align: center;
  margin-top: 1%;
  font-weight: lighter;
}

.saveTaskButtonContainer{
  margin-top: 3%;
  margin-bottom: 2%;
  width: 100%;
  height: 40%;
  /* border: 2px solid black; */
  display: flex;
  justify-content: center;
  align-items: center;
 }

.submitButton{
  background-color: #3393EC;
  font-size: 14px;
  letter-spacing: 1.5px;
  /* font-family: Euclid Circular B; */
  font-weight: bold;
  height: 54px;
  width: 50%;
  color: #F5F5F5;
  border: none;  
}

.submitButton:active{
  /* box-shadow: 0 5px #666; */
  transform: translateY(2px);
}

/* Divisionnnnnn */

.donorItems input[type=text] {
  padding: 10px;
  font-size: 17px;
  border: 1px solid grey;
  float: left;
  width: 80%;
  background: #f3f3f3;
  /* margin-bottom: 5%; */
  margin-top: 10%;

}

.donorItems button {
  float: left;
  width: 20%;
  padding: 10px;
  margin-top: 10%;

  background: #2196F3;
  color: white;
  font-size: 17px;
  border: 1px solid grey;
  border-left: none;
  cursor: pointer;
}

.donorItems button:hover {
  background: #0b7dda;
}

.donorItems::after {
  content: "";
  clear: both;
  display: table;
}

/* Divisionnnnnjjjjjn */



.form{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

}

.form .txt_field{
  position: relative;
  border-bottom: 2px solid #adadad;
  margin-top: 30px;
}
.txt_field input{
  width: 100%;
  padding: 0 5px;
  height: 40px;
  font-size: 16px;
  border: none;
  background: none;
  outline: none;
}
.txt_field label{
  position: absolute;
  top: 50%;
  left: 5px;
  color: #adadad;
  transform: translateY(-50%);
  font-size: 16px;
  pointer-events: none;
  transition: .5s;
  color: black;
}
.txt_field span::before{
  content: '';
  position: absolute;
  top: 40px;
  left: 0;
  width: 0%;
  height: 2px;
  background: #2691d9;
  transition: .5s;
}
.txt_field input:focus ~ label,
.txt_field input:valid ~ label{
  top: -5px;
  color: #2691d9;
}
.txt_field input:focus ~ span::before,
.txt_field input:valid ~ span::before{
  width: 100%;
}
.pass{
  margin: -5px 0 20px 5px;
  color: #a6a6a6;
  cursor: pointer;
}
.pass:hover{
  text-decoration: underline;
}



.dateandtimeContainer{
  margin-top: 2%;
  width: 50%;
  height: 100px;
  /* border: 2px solid black; */
  display: flex;
  justify-content: center; 
  align-items: center; 
}

.dateandtimeContainer input[type=time] {
  padding: 10px;
  font-size: 20px;
  border: 1px solid grey;
  float: left;
  width: 100%;
  background: #f3f3f3;
  /* margin-bottom: 5%; */
  /* margin-top: 10%; */

}

.dateandtimeContainer input[type=date] {
  padding: 10px;
  font-size: 20px;
  border: 1px solid grey;
  float: left;
  width: 100%;
  background: #f3f3f3;
  /* margin-bottom: 5%; */
  /* margin-top: 10%; */

}





.toggleButtonContainer{
  margin-top: 2%;
  width: 25%;
  height: 40px;
  /* border: 2px solid black; */
  display: flex;
  justify-content: center; 
  align-items: center; 
  /* flex-direction: column; */

}

.labelReadorNot{
  padding-right: 6%;
  color: black;
  text-align: center;
  /* color: white; */
  font-size: 24px;
  font-weight: 600;
  /* margin-bottom: 2%; */

}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.mainText{
  text-align: center;
}


.linkContainer{
  /* width: 100%;
  height: 100%; */

  /* flex-direction: column; */
  /* gap: 5%; */
  margin-top: 5%;
}